<template>
  <div class="contact">
    <back-arrow />

    <div class="mailbox stick-to-floor animate__animated animate__slideInRight">
        <svg xmlns="http://www.w3.org/2000/svg" width="123.136" height="204.932" viewBox="0 0 123.136 204.932">
          <g id="Mailbox" transform="translate(-766.96 -800.068)">
            <rect id="Rectangle_83" data-name="Rectangle 83" width="29" height="143" transform="translate(817 862)" fill="#714d28"/>
            <g id="Group_54" data-name="Group 54">
              <rect id="Rectangle_84" data-name="Rectangle 84" width="12" height="35" transform="translate(829.412 800.068) rotate(14)" fill="#bc741f"/>
              <rect id="Rectangle_85" data-name="Rectangle 85" width="12" height="35" transform="translate(826.568 811.712) rotate(-76)" fill="#bc741f"/>
            </g>
            <g id="Group_55" data-name="Group 55">
              <path id="Path_26" data-name="Path 26" d="M27.13.807h74.358C125.779,3.169,123,72,123,72H0S-2.246,6.229,27.13.807Z" transform="translate(767 823)" fill="#bc741f"/>
              <path id="Path_27" data-name="Path 27" d="M26.548.807h6.7C57.017,2.794,54.3,60.686,54.3,60.686H0S-2.2,5.367,26.548.807Z" transform="translate(772 828.939)" fill="#714d28"/>
            </g>
          </g>
        </svg>
    </div>

    <div class="wrapper foreground blend-white-bg regular-padding">
      <h1>Contact</h1>
      <p>Thank you for contacting me! I am working on a form to send an e-mail to my personal mail.</p>
      <p>Until then, please find me on <a href="https://www.linkedin.com/in/jeroen-bosch-83201b104">LinkedIn</a> and drop me a message there!</p>
      
    </div>
  </div>
</template>

<script>
//import {database} from '../firebase'
//import {tumblrClient} from '../tumblr'
import BackArrow from '../components/BackArrow.vue'

export default {
  name: 'Contact',
  components: {BackArrow},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
}
</script>


<style lang="scss">
  @import '@/scss/_variables.scss';

  .contact {
    display: flex;

    .mailbox {
      margin-bottom: -10px;
      right: scale_width(200);
      height: scale_height(200);

      svg {
        height: 100%;
        width: fit-content;
      }
    }

    .wrapper {
      width: 540px;
      margin: scale_height(120) auto;
    }
  }
</style>
